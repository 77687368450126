import { PartySubjectType } from "@interfaces/IParty";
import { useXpert } from "@hooks/xpert/useXpert";
import { useClient } from "@hooks/client/useClient";
import { useCompany } from "@hooks/company/useCompany";
import { usePerson } from "@hooks/person/usePerson";
import { ProtectedContractTypeCodes } from "@interfaces/IContractType";
import { IContractGenerationSubject } from "@hooks/contract/useContractGeneration";

import IClient from "@interfaces/IClient";
import IXpert from "@interfaces/IXpert";
import CompanyService from "@services/CompanyService";
import XchangeService from "@services/XchangeService";
import ICompany, { ProtectedCompaniesCodes } from "@interfaces/ICompany";
import IXchange from "@interfaces/IXchange";
import XpertService from "@services/XpertService";
import ClientService from "@services/ClientService";
import PersonService from "@services/PersonService";
import { formatDateToString } from "./date-utils";
import IPerson from "@interfaces/IPerson";
import { isNil } from "lodash";
import { toCurrencyString } from "@helpers/currencyUtils";
import { cleanRichText } from "@helpers/string-utils";
import {
  calcXchangeTotalBudgetsByCurrency,
  calcXchangeXpertBudget,
  getXchangeCurrenciesString,
  getXchangeDurationInweeks,
  getXchangeTerms,
  getXchangeXpertIds,
  getXpertXchangeDurationInWeeks,
  getXpertXchangeStartDate,
  getXpertXchangeTerms,
} from "@helpers/xchange-utils";

export const isContractTypeGroupBySubject = (
  contractType: ProtectedContractTypeCodes
) => {
  return (
    contractType === ProtectedContractTypeCodes.XPERT_SOW ||
    contractType === ProtectedContractTypeCodes.CLIENT_SOW
  );
};

export const getContractTypeTranslationRootKey = (
  contractType: ProtectedContractTypeCodes
) => {
  switch (contractType) {
    case ProtectedContractTypeCodes.CLIENT_MSA:
    case ProtectedContractTypeCodes.XPERT_MSA:
      return "contracts.msa";

    case ProtectedContractTypeCodes.CLIENT_SOW:
      return "contracts.client_sow";
    case ProtectedContractTypeCodes.XPERT_SOW:
      return "contracts.xpert_sow";

    default:
      return "contracts";
  }
};

export const getSubjectObjectHook = (subject: IContractGenerationSubject) => {
  switch (getSubjectType(subject)) {
    case PartySubjectType.Xpert:
      return useXpert;
    case PartySubjectType.Client:
      return useClient;
    case PartySubjectType.Company:
      return useCompany;
    case PartySubjectType.Person:
      return usePerson;
  }
};

export const getSubjectObject = (subject: IContractGenerationSubject) => {
  switch (getSubjectType(subject)) {
    case PartySubjectType.Xpert:
      return XpertService.getXpert(subject.subjectId);
    case PartySubjectType.Client:
      return ClientService.getClient(subject.subjectId);
    case PartySubjectType.Company:
      return CompanyService.getCompany(subject.subjectId);
    case PartySubjectType.Person:
      return PersonService.getPerson(subject.subjectId);
  }
};

export const getSubjectType = (
  subject: IContractGenerationSubject
): PartySubjectType => {
  switch (subject.contractType) {
    case ProtectedContractTypeCodes.CLIENT_MSA:
    case ProtectedContractTypeCodes.CLIENT_SOW:
      return PartySubjectType.Client;

    case ProtectedContractTypeCodes.XPERT_MSA:
    case ProtectedContractTypeCodes.XPERT_SOW:
      return PartySubjectType.Xpert;
  }
};

export const getSubjectTypes = (subjects: IContractGenerationSubject[]) => {
  const subjectTypes = subjects.map((subject) => getSubjectType(subject));
  return [...new Set(subjectTypes)];
};

export const filterSubjectsByContractType = (
  subjects: IContractGenerationSubject[],
  contractType: ProtectedContractTypeCodes
) => {
  return subjects.filter((subject) => subject.contractType === contractType);
};

export const getContractTypes = (subjects: IContractGenerationSubject[]) => {
  const contractTypes = subjects.map((subject) => subject.contractType);
  return [...new Set(contractTypes)];
};

export const getSubjectContractExtraCompanyParties = (
  subject: IContractGenerationSubject
): ProtectedCompaniesCodes[] => {
  return [ProtectedCompaniesCodes.PXW];
};

export const getSubjectContractTitle = async (
  subject: IContractGenerationSubject
): Promise<string> => {
  const subjectObject = await getSubjectObject(subject);

  const currentDate = new Date();
  const month = currentDate.toLocaleString("en-US", { month: "2-digit" });
  const year = currentDate.getFullYear();

  let company: ICompany | undefined;
  if (
    [
      ProtectedContractTypeCodes.CLIENT_MSA,
      ProtectedContractTypeCodes.XPERT_MSA,
    ].includes(subject.contractType)
  ) {
    company = await CompanyService.getCompany(
      (subjectObject as IXpert | IClient).object_id!
    );
  }

  let xchange: IXchange | undefined;
  if (
    [
      ProtectedContractTypeCodes.CLIENT_SOW,
      ProtectedContractTypeCodes.XPERT_SOW,
    ].includes(subject.contractType)
  ) {
    xchange = await XchangeService.getXchange(subject.filters!.xchange!);
  }

  switch (subject.contractType) {
    case ProtectedContractTypeCodes.CLIENT_MSA:
      return `${company?.company_name}_Services MSA`;

    case ProtectedContractTypeCodes.CLIENT_SOW:
      const xpertIds = getXchangeXpertIds(xchange!);
      const xperts = await XpertService.getXperts({
        id__in: xpertIds.join(","),
      });
      const xpertNames = xperts.map((xpert) => xpert.name).join(", ");

      return `[${xchange?.project_code}] ${
        (subjectObject as IClient).name
      } CSOW ${xchange?.name} (by ${xpertNames}) ${month}-${year}`;

    case ProtectedContractTypeCodes.XPERT_MSA:
      return `${company?.company_name}_Subcontractor MSA`;

    case ProtectedContractTypeCodes.XPERT_SOW:
      return `[${xchange?.project_code}] ${
        (subjectObject as IXpert).name
      } XpertSOW (${xchange?.name}, for ${
        xchange?.client?.name
      }) ${month}-${year}`;
  }
};

export const getSubjectContractData = async (
  subject: IContractGenerationSubject
): Promise<object> => {
  const subjectObject = await getSubjectObject(subject);

  let company: ICompany | undefined;
  if (
    [
      ProtectedContractTypeCodes.CLIENT_MSA,
      ProtectedContractTypeCodes.XPERT_MSA,
      ProtectedContractTypeCodes.CLIENT_SOW,
    ].includes(subject.contractType)
  ) {
    company = await CompanyService.getCompany(
      (subjectObject as IXpert | IClient).object_id!
    );
  }

  let primaryContact: IPerson | undefined;
  if (!isNil((subjectObject as IClient | IXpert).primary_contact)) {
    primaryContact = await PersonService.getPerson(
      (subjectObject as IClient | IXpert).primary_contact!
    );
  }

  let financeContact: IPerson | undefined;
  if (
    [
      ProtectedContractTypeCodes.CLIENT_MSA,
      ProtectedContractTypeCodes.XPERT_MSA,
    ].includes(subject.contractType)
  ) {
    if (!isNil((subjectObject as IClient | IXpert).finance_contact)) {
      financeContact = await PersonService.getPerson(
        (subjectObject as IClient | IXpert).finance_contact!
      );
    }
  }

  let xchange: IXchange | undefined;
  if (
    [
      ProtectedContractTypeCodes.CLIENT_SOW,
      ProtectedContractTypeCodes.XPERT_SOW,
    ].includes(subject.contractType)
  ) {
    xchange = await XchangeService.getXchange(subject.filters!.xchange!);
  }

  switch (subject.contractType) {
    case ProtectedContractTypeCodes.CLIENT_MSA:
      return {
        Date: formatDateToString(new Date(), {}, "en-CA"),
        "Company Legal Name": company?.company_legal_name! || "",
        "Entity Type": company?.legal_entity_type || "",
        Jurisdiction: company?.legal_entity_jurisdiction! || "",
        "Company Address": `${company?.street || ""} ${company?.region || ""} ${
          company?.country || ""
        } ${company?.postal_code || ""}`.trim(),
        "Primary Contact Name": `${primaryContact?.first_name || ""} ${
          primaryContact?.last_name || ""
        }`.trim(),
        "Primary Contact Title": primaryContact?.title || "",
        "Primary Contact Phone": primaryContact?.phone || "",
        "Primary Contact Email": primaryContact?.email || "",
        "Primary Finance Contact Name": `${financeContact?.first_name || ""} ${
          financeContact?.last_name || ""
        }`.trim(),
        "Primary Finance Contact Phone": financeContact?.phone || "",
        "Invoice Email": (subjectObject as IClient).invoice_email || "",
      };

    case ProtectedContractTypeCodes.CLIENT_SOW:
      const xpertSOWClient = subjectObject as IClient;

      if (!xchange) {
        return {};
      }

      const xpertIds = getXchangeXpertIds(xchange);
      const xperts = await XpertService.getXperts({
        id__in: xpertIds.join(","),
      });
      const xpertNames = xperts.map((xpert) => xpert.name).join(", ");

      const xchangeBudgetsWithCurrency =
        calcXchangeTotalBudgetsByCurrency(xchange);

      const xchangeDuration = getXchangeDurationInweeks(xchange);

      const xchangeTerms = getXchangeTerms(xchange);
      const termTableObjects = xchangeTerms.map((term) => ({
        xpertName:
          xperts.find((xpert) => xpert.id === term.xpert_involved)?.name || "?",
        termName: term.title ?? "-",
        description: term.description ? cleanRichText(term.description) : "-",
        startDate: term.start_date
          ? formatDateToString(new Date(term.start_date), {
              dateStyle: "medium",
            })
          : "-",
        duration: (term.duration! * term.multiplier!).toString() ?? "-",
        cost: `${term.currency} ${toCurrencyString(
          term.cost_per_sprint! * term.multiplier!,
          "USD",
          2
        )}`,
      }));

      termTableObjects.sort((a, b) => {
        if (a.xpertName < b.xpertName) {
          return -1;
        } else if (a.xpertName > b.xpertName) {
          return 1;
        } else {
          if (a.startDate < b.startDate) {
            return -1;
          } else if (a.startDate > b.startDate) {
            return 1;
          } else {
            return 0;
          }
        }
      });

      const xchangeEpics = await XchangeService.getEpics(xchange.id!);
      xchangeEpics.sort((a, b) => {
        if (a.order_index && b.order_index) {
          return a.order_index - b.order_index;
        }
        return 0;
      });

      return {
        Date: formatDateToString(new Date(), {
          dateStyle: "medium",
        }),
        "Xchange Title": xchange?.name ?? "",
        "Project Code": xchange?.project_code ?? "",
        "Client Legal Name": company?.company_legal_name ?? "",
        "MSA Date": xpertSOWClient.msa_effective_date
          ? formatDateToString(new Date(xpertSOWClient.msa_effective_date), {
              dateStyle: "medium",
            })
          : "",
        "Xchange Description": xchange?.description
          ? cleanRichText(xchange?.description)
          : "",
        "Xchange Objective": xchange?.objective
          ? cleanRichText(xchange?.objective)
          : "",
        "Primary Contact Name": `${primaryContact?.first_name || ""} ${
          primaryContact?.last_name || ""
        }`.trim(),
        "Primary Contact Title": primaryContact?.title || "",
        "Subcontractor Legal Name": xpertNames ?? "",
        "Start Date": xchange.start_date
          ? formatDateToString(new Date(xchange.start_date), {
              dateStyle: "medium",
            })
          : "",
        "Total Estimated Timeline": xchangeDuration.toString() ?? "",
        Currency: getXchangeCurrenciesString(xchange) ?? "",
        "Total Estimated Cost": xchangeBudgetsWithCurrency
          .map((budget) =>
            budget.total
              ? `${budget.currency} ${toCurrencyString(budget.total, "USD", 2)}`
              : ""
          )
          .join(", "),
        terms: termTableObjects.map((term) => [
          term.xpertName,
          term.termName,
          term.description,
          term.startDate,
          term.duration,
          term.cost,
        ]),
        epics: xchangeEpics.map((epic) => {
          return [
            epic.order_index?.toString() ?? "-",
            epic.title ?? "-",
            epic.description ? cleanRichText(epic.description) : "",
            (epic.duration! / 7)?.toString() ?? "-",
            xperts
              .filter((xpert) => epic.xperts?.includes(xpert.id!))
              .map((xpert) => xpert.name)
              .join(", "),
          ];
        }),
      };

    case ProtectedContractTypeCodes.XPERT_MSA:
      const xpertMSAXpert = subjectObject as IXpert;
      return {
        Date: formatDateToString(new Date(), {}, "en-CA"),
        "Company Legal Name": company?.company_legal_name! || "",
        "Entity Type": company?.legal_entity_type || "",
        Jurisdiction: company?.legal_entity_jurisdiction! || "",
        "Insurance Coverage":
          xpertMSAXpert.insurance_coverage?.toString() || "",
        "Company Address": `${company?.street || ""} ${company?.region || ""} ${
          company?.country || ""
        } ${company?.postal_code || ""}`.trim(),
        "Primary Contact Name": `${primaryContact?.first_name || ""} ${
          primaryContact?.last_name || ""
        }`.trim(),
        "Primary Contact Title": primaryContact?.title || "",
        "Primary Contact Phone": primaryContact?.phone || "",
        "Primary Contact Email": primaryContact?.email || "",
        "Primary Finance Contact Name": `${financeContact?.first_name || ""} ${
          financeContact?.last_name || ""
        }`.trim(),
        "Primary Finance Contact Phone": financeContact?.phone || "",
        "Primary Finance Contact Email": financeContact?.email || "",
        "GST HST Number": xpertMSAXpert.bank_details?.taxId || "",
        "Institution Number": xpertMSAXpert.bank_details?.institution || "",
        "Transit Number": xpertMSAXpert.bank_details?.transit || "",
        "Account Number": xpertMSAXpert.bank_details?.account || "",
        "Account Name": xpertMSAXpert.bank_details?.name || "",
        "Account Type": xpertMSAXpert.bank_details?.accountType || "",
        "Routing Number": xpertMSAXpert.bank_details?.routing || "",
        "Swift Number": xpertMSAXpert.bank_details?.swift || "",
      };

    case ProtectedContractTypeCodes.XPERT_SOW:
      const xpertSOWXpert = subjectObject as IXpert;

      if (!xchange) {
        return {};
      }

      const xpertBudget = calcXchangeXpertBudget(xchange, subject.subjectId);
      const terms = getXpertXchangeTerms(xchange, subject.subjectId);
      const xpertStartDate = getXpertXchangeStartDate(
        xchange,
        subject.subjectId
      );
      const xpertDurationWeeks = getXpertXchangeDurationInWeeks(
        xchange,
        subject.subjectId
      );

      let epics = await XchangeService.getEpics(xchange.id!);
      epics = epics.filter((epic) => epic.xperts?.includes(subject.subjectId));
      epics.sort((a, b) => {
        if (a.order_index && b.order_index) {
          return a.order_index - b.order_index;
        }
        return 0;
      });

      return {
        Date: formatDateToString(new Date(), {
          dateStyle: "medium",
        }),
        "Xchange Title": xchange?.name ?? "",
        "Project Code": xchange?.project_code ?? "",
        "Subcontractor Legal Name": xpertSOWXpert.legal_name ?? "",
        "MSA Date": xpertSOWXpert.msa_effective_date
          ? formatDateToString(new Date(xpertSOWXpert.msa_effective_date), {
              dateStyle: "medium",
            })
          : "",
        "Client Legal Name": xchange?.client?.name ?? "",
        "Xchange Description": xchange?.description
          ? cleanRichText(xchange?.description)
          : "",
        "Xchange Objective": xchange?.objective
          ? cleanRichText(xchange?.objective)
          : "",
        "Subcontractor Start Date": xpertStartDate
          ? formatDateToString(xpertStartDate, {
              dateStyle: "medium",
            })
          : "",
        "Subcontractor Total Timeline": xpertDurationWeeks.toString(),
        "Primary Contact Name": primaryContact
          ? `${primaryContact.first_name || ""} ${
              primaryContact.last_name || ""
            }`.trim()
          : "",
        "Primary Contact Title": primaryContact
          ? primaryContact.title?.trim()
          : "",
        Currency: terms[0].currency,
        "Total Subcontractor Cost Estimate": `${
          terms[0].currency
        } ${toCurrencyString(xpertBudget, "USD", 2)}`,
        terms: [
          ...terms.map((term) => {
            return [
              term.title,
              term.description ? cleanRichText(term.description) : "-",
              (term.duration! * term.multiplier!).toString(),
              `${term.currency} ${toCurrencyString(
                term.cost_per_sprint! * term.multiplier!,
                "USD",
                2
              )}`,
            ];
          }),
        ],
        epics: [
          ...epics.map((epic) => {
            return [
              epic.order_index?.toString(),
              epic.title,
              (epic.duration! / 7)?.toString(),
              epic.description ? cleanRichText(epic.description) : "",
            ];
          }),
        ],
      };
  }
};
