import ICompany, { ICompanyCreateUpdate } from "@interfaces/ICompany";
import IPage, { IPageParams } from "@interfaces/IPage";
import Service from "@services/Service";

export interface IGetCompaniesParams {
  id__in?: string;
  id__not__in?: string;
  status__in?: string;
  type__in?: string;
  search?: string;
  ordering?: string;
  code?: string;
  code__in?: string;
  company_name__iexact?: string;
  company_legal_name__iexact?: string;
}

export default class CompanyService extends Service {
  public static async getCompanies(params?: IGetCompaniesParams & IPageParams) {
    const response = await this.axios.get<IPage<ICompany>>("/companies", {
      params,
    });
    return response.data;
  }

  public static async getCompany(id: number) {
    const response = await this.axios.get<ICompany>(`/companies/${id}`);
    return response.data;
  }

  public static async createCompany(company: ICompanyCreateUpdate) {
    const response = await this.axios.post<ICompany>("/companies", company);
    return response.data;
  }

  public static async updateCompany(company: ICompanyCreateUpdate) {
    const response = await this.axios.patch<ICompany>(
      `/companies/${company.id}`,
      company
    );
    return response.data;
  }

  public static async uploadCompanyLogo(companyId: number, file: File) {
    const formData = new FormData();
    formData.append("company_logo", file);
    const response = await this.axios.patch(
      `/companies/${companyId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  }
}
