import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import ContractSignatoriesWizard, {
  ContractSignatoriesFormValues,
} from "@components/ContractSignatoriesWizard/ContractSignatoriesWizard";
import { useTranslation } from "react-i18next";
import {
  IContractGenerationHook,
  IContractGenerationSubject,
} from "@hooks/contract/useContractGeneration";
import { useState } from "react";
import useAreAllSubjectSignatureInvitesSent from "@hooks/contract/useAreAllSubjectSignatureInvitesSent";
import useCanSignNowBeGenerated from "@hooks/contract/useCanSignNowBeGenerated";
import useIsSubjectSignNowGenerating from "@hooks/contract/useIsSubjectSignNowGenerating";
import useGetSubjectContract from "@hooks/contract/useGetSubjectContract";
import { ContractStatus } from "@interfaces/IContract";
import useCanSignNowBeRevoked from "@hooks/contract/useCanSignNowBeRevoked";
import SignNowRevokeWizard from "@components/SignNowRevokeWizard/SignNowRevokeWizard";

const SignNowGenerationButton = ({
  subject,
  contractGenerationHook,
  className,
  contractTranslationRootKey = "contracts",
}: {
  subject: IContractGenerationSubject;
  contractGenerationHook: IContractGenerationHook;
  className?: string;
  contractTranslationRootKey?: string;
}) => {
  const { t } = useTranslation();

  const { initSignNow } = contractGenerationHook;

  const contract = useGetSubjectContract(subject, contractGenerationHook, true);

  const canSignNowBeGenerated = useCanSignNowBeGenerated(
    subject,
    contractGenerationHook
  );
  const isSignNowInitializing = useIsSubjectSignNowGenerating(
    subject,
    contractGenerationHook
  );

  const canSignNowBeRevoked = useCanSignNowBeRevoked(
    subject,
    contractGenerationHook
  );

  const [showRevokeConfirmationModal, setShowRevokeConfirmationModal] =
    useState(false);

  const [showSignatoriesModal, setShowSignatoriesModal] = useState(false);

  const onSignatoriesSelectionFinish = async (
    data: ContractSignatoriesFormValues,
    callback: Function
  ) => {
    await initSignNow(data.partySignatories);
    callback();
  };

  const areAllInvitesSent = useAreAllSubjectSignatureInvitesSent(
    subject,
    contractGenerationHook
  );

  return (
    <div className={className}>
      {contract?.status! >= ContractStatus.SENT_FOR_SIGNING && (
        <>
          <OverlayTrigger
            delay={{ show: 350, hide: 0 }}
            overlay={
              <Tooltip>
                {canSignNowBeRevoked
                  ? t(`${contractTranslationRootKey}.dashboard.signnow.revoke`)
                  : t(
                      `${contractTranslationRootKey}.dashboard.signnow.cannot_revoke`
                    )}
              </Tooltip>
            }
          >
            <span className="me-2">
              <Button
                variant="outline-light"
                size="sm"
                onClick={() => {
                  setShowRevokeConfirmationModal(true);
                }}
                disabled={!canSignNowBeRevoked}
              >
                <i
                  className={`fa-solid fa-reply ${
                    isSignNowInitializing && "fa-spin"
                  }`}
                />
              </Button>
            </span>
          </OverlayTrigger>
        </>
      )}

      {!areAllInvitesSent && (
        <Button
          disabled={!subject || !canSignNowBeGenerated}
          variant="primary"
          onClick={() => setShowSignatoriesModal(true)}
          size="sm"
        >
          {isSignNowInitializing ? (
            <i className="fa-solid fa-spinner-third fa-spin me-2"></i>
          ) : (
            <i className="fa-regular fa-file-signature me-2"></i>
          )}
          {isSignNowInitializing
            ? t(`${contractTranslationRootKey}.dashboard.signnow.initializing`)
            : t(`${contractTranslationRootKey}.dashboard.signnow.initiate`)}
        </Button>
      )}

      {showSignatoriesModal && (
        <ContractSignatoriesWizard
          show={showSignatoriesModal}
          contractGenerationHook={contractGenerationHook}
          subject={subject}
          onClose={() => {
            setShowSignatoriesModal(false);
          }}
          onFinish={() => {
            setShowSignatoriesModal(false);
          }}
          onSignatorieSelectionFinish={(data, callback) => {
            onSignatoriesSelectionFinish(data, callback);
          }}
          areSignatoriesCreationLoading={isSignNowInitializing}
          contractTranslationRootKey={contractTranslationRootKey}
        />
      )}

      {showRevokeConfirmationModal && (
        <SignNowRevokeWizard
          show={showRevokeConfirmationModal}
          contractGenerationHook={contractGenerationHook}
          subject={subject}
          onClose={() => {
            setShowRevokeConfirmationModal(false);
          }}
          onFinish={() => {
            setShowRevokeConfirmationModal(false);
          }}
          contractTranslationRootKey={contractTranslationRootKey}
        />
      )}
    </div>
  );
};

export default SignNowGenerationButton;
