import IClient from "@interfaces/IClient";
import IXchangeTerm from "@interfaces/terms/IXchangeTerm";
import IEpic from "@interfaces/IEpic";
import IXchangeProduct from "@interfaces/products/IXchangeProduct";
import { Modify } from "@helpers/typing";

export enum XchangeTypes {
  XCHANGE = "xchange",
  ADVISORY = "advisory",
}

export enum XchangeStatuses {
  PLANNING = "planning",
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
  ARCHIVED = "archived",
  UNFULFILLED = "unfulfilled",
}

export default interface IXchange {
  id?: number;
  name?: string;
  client?: IClient;
  project_code?: string;
  description?: string;
  objective?: string;
  type?: string | XchangeTypes;
  status?: XchangeStatuses;
  folder_id?: string;
  start_date?: string | null;
  goal_end_date?: string | null;
  was_goal_end_date_set_by_user?: boolean;
  actual_cost?: number;
  creation_date?: string;
  updated_at?: Date;
  xchange_terms?: IXchangeTerm[];
  xchange_products?: IXchangeProduct[];
  epics?: IEpic[];
  completed_sections?: string[];
}

export type IXchangeCreateUpdate = Modify<
  IXchange,
  {
    client?: number | null;
  }
>;
