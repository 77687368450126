import ITemplate from "@interfaces/ITemplate";

// Protected contract types are system created and cannot be deleted by the user
export enum ProtectedContractTypeCodes {
  XPERT_MSA = "xpert_msa",
  CLIENT_MSA = "client_msa",
  XPERT_SOW = "xpert_sow",
  CLIENT_SOW = "client_sow",
}

interface IContractType {
  id?: string;
  name?: string;
  code?: string | ProtectedContractTypeCodes;
  default_template?: string | ITemplate;
  is_protected?: boolean;
}

export default IContractType;
