import { useContractCreate } from "./useContractCreate";
import { useContractGenerate } from "./useContractGenerate";
import { usePartyCreate } from "@hooks/party/usePartyCreate";
import { useCreateSignatory } from "@hooks/signatory/useCreateSignatory";
import { useCreateSignNowSignature } from "@hooks/signature/useCreateSignNowSignature";
import { useContracts } from "./useContracts";
import { useParties } from "@hooks/party/useParties";
import { useSignatories } from "@hooks/signatory/useSignatories";
import { useSignatures } from "@hooks/signature/useSignatures";
import IContract, { ContractStatus } from "@interfaces/IContract";
import { ProtectedContractTypeCodes } from "@interfaces/IContractType";
import ISignatory from "@interfaces/ISignatory";
import IParty, { PartySubjectType } from "@interfaces/IParty";
import ISignNowSignature from "@interfaces/ISignNowSignature";
import { useSignNowSignatureUpdate } from "@hooks/signature/useSignNowSignatureUpdate";
import CompanyService from "@services/CompanyService";
import { getModelHyperLink } from "@helpers/model-utils";
import { useReviewerCreate } from "@hooks/reviewer/useReviewerCreate";
import { useContractUpdate } from "./useContractUpdate";
import { IPartySignatory } from "@components/ContractSignatoriesWizard/ContractPartySignatorySelection/ContractPartySignatorySelection";
import { IGetContractsParams } from "@services/ContractService";
import {
  getContractTypes,
  getSubjectContractData,
  getSubjectContractExtraCompanyParties,
  getSubjectContractTitle,
  getSubjectType,
  getSubjectTypes,
} from "@helpers/contract-utils";
import IReviewer from "@interfaces/IReviewer";
import { useReviewerDelete } from "@hooks/reviewer/useReviewerDelete";
import { useReviewers } from "@hooks/reviewer/useReviewers";
import { useDeleteSignatory } from "@hooks/signatory/useDeleteSignatory";

export interface IContractGenerationSubject {
  subjectId: number;
  contractType: ProtectedContractTypeCodes;
  filters?: {
    xchange?: number;
  };
  folderId?: string;
}

export interface IContractGenerationHook {
  subjects: IContractGenerationSubject[];
  expectedContractTypes?: ProtectedContractTypeCodes[];
  contracts?: IContract[];
  generatePreview: (subject: IContractGenerationSubject) => Promise<void>;
  generateFinal: (
    reviewers: number[],
    subject: IContractGenerationSubject
  ) => Promise<void>;
  initSignNow: (partySignatories: IPartySignatory[]) => void;
  areContractsLoading: boolean;
  isContractGenerating: boolean;
  arePartiesLoaded?: boolean;
  parties?: IParty[];
  signatories?: ISignatory[];
  signatures?: ISignNowSignature[];
  reviewers?: IReviewer[];
  updateReviewers: (
    reviewers: number[],
    subject: IContractGenerationSubject
  ) => Promise<void>;
  error?: unknown;
}

export default function useContractGeneration(
  subjects: IContractGenerationSubject[],
  expectedContractTypes?: ProtectedContractTypeCodes[]
): IContractGenerationHook {
  const contractCreateMutation = useContractCreate();
  const contractUpdateMutation = useContractUpdate();
  const generateContractMutation = useContractGenerate();
  const partyCreateMutation = usePartyCreate();
  const signatoryCreateMutation = useCreateSignatory();
  const signatoryDeleteMutation = useDeleteSignatory();
  const signNowCreateMutation = useCreateSignNowSignature();
  const signNowUpdateMutation = useSignNowSignatureUpdate();
  const createReviewerMutation = useReviewerCreate();
  const deleteReviewerMutation = useReviewerDelete();

  const extraContractFilters = (() => {
    const extraFilters: IGetContractsParams = {};

    const xchangeFilters = subjects
      .filter((subject) => !!subject.filters?.xchange)
      .map((subject) => subject.filters!.xchange);

    if (xchangeFilters.length > 0) {
      extraFilters.xchange__id__in = xchangeFilters.join(",");
    }

    return extraFilters;
  })();

  const {
    isLoading: areContractsLoading,
    isFetching: areContractsFetching,
    data: contracts,
  } = useContracts(
    {
      party__subject_id__in: subjects
        .filter((subject) => !!subject.subjectId)
        .map((subject) => subject.subjectId)
        .join(","),
      party__subject_type__model__in: getSubjectTypes(subjects).join(","),
      party__main: true,
      contract_type__code__in: getContractTypes(subjects).join(","),
      ...extraContractFilters,
    },
    {
      enabled: subjects.filter((subject) => !!subject.subjectId).length > 0,
      staleTime: 1000 * 60 * 5,
    }
  );

  const { data: reviewers } = useReviewers(
    {
      contract__in: contracts?.map((contract) => contract.id).join(","),
    },
    {
      enabled: !!contracts?.length && !areContractsFetching,
      staleTime: 1000 * 60 * 5,
    }
  );

  const {
    isSuccess: arePartiesLoaded,
    isFetching: arePartiesFetching,
    data: parties,
  } = useParties(
    {
      contract__in: contracts?.map((contract) => contract.id).join(","),
    },
    {
      enabled: !!contracts && contracts.length > 0 && !areContractsFetching,
      staleTime: 1000 * 60 * 5,
    }
  );

  const { isFetching: areSignatoriesFetching, data: signatories } =
    useSignatories(
      {
        party__in: parties?.map((party) => party.id).join(","),
      },
      {
        enabled: !!parties?.length && !arePartiesFetching,
        staleTime: 1000 * 60 * 5,
      }
    );

  const { data: signatures } = useSignatures(
    {
      signatory__in: signatories?.map((signatory) => signatory.id).join(","),
    },
    {
      enabled: !!signatories?.length && !areSignatoriesFetching,
      staleTime: 1000 * 60 * 5,
    }
  );

  const generatePreview = async (subject: IContractGenerationSubject) => {
    const subjectType = getSubjectType(subject);
    const title = await getSubjectContractTitle(subject);
    const data = await getSubjectContractData(subject);

    const subjectContract = contracts?.find(
      (contract) => contract.subject_id === subject.subjectId
    );

    if (subjectContract && subjectContract.last_generation_is_preview) {
      await generateContractMutation.mutateAsync({
        contractId: subjectContract.id!,
        params: {
          is_preview: true,
          name: title,
          data,
        },
      });
    } else {
      const newContract = await contractCreateMutation.mutateAsync({
        contract: {
          name: title,
          contract_type: subject.contractType,
          xchange: subject.filters?.xchange ?? undefined,
          folder_id: subject.folderId,
          data: data,
        },
      });

      if (newContract.id) {
        await Promise.all([
          partyCreateMutation.mutateAsync({
            party: {
              subject: `/${getModelHyperLink(subjectType)}/${
                subject.subjectId
              }`,
              contract: newContract.id,
              main: true,
            },
          }),
          ...getSubjectContractExtraCompanyParties(subject).map(
            async (companyCode) => {
              const companiesData = await CompanyService.getCompanies({
                code: companyCode,
                page_size: 1,
              });

              if (companiesData.results.length === 1) {
                return partyCreateMutation.mutateAsync({
                  party: {
                    subject: `/${getModelHyperLink(PartySubjectType.Company)}/${
                      companiesData.results[0].id
                    }`,
                    contract: newContract.id,
                    main: false,
                  },
                });
              } else {
                return Promise.resolve();
              }
            }
          ),
        ]);

        await generateContractMutation.mutateAsync({
          contractId: newContract.id,
          params: {
            is_preview: true,
          },
        });
      }
    }
  };

  const generateFinal = async (
    reviewers: number[],
    subject: IContractGenerationSubject
  ) => {
    const latestSubjectNonPreviewContract = contracts?.find(
      (contract) =>
        contract.subject_id === subject.subjectId &&
        !contract.last_generation_is_preview
    );
    const latestSubjectPreviewContract = contracts?.find(
      (contract) =>
        contract.subject_id === subject.subjectId &&
        contract.last_generation_is_preview
    );

    if (latestSubjectNonPreviewContract) {
      await contractUpdateMutation.mutateAsync({
        id: latestSubjectNonPreviewContract.id,
        status: ContractStatus.ARCHIVED,
      });
    }

    if (latestSubjectPreviewContract && parties && parties.length > 0) {
      await generateContractMutation.mutateAsync({
        contractId: latestSubjectPreviewContract.id!,
        params: {
          is_preview: false,
        },
      });

      if (reviewers.length > 0) {
        await Promise.all(
          reviewers.map((reviewer) => {
            return createReviewerMutation.mutateAsync({
              person: reviewer,
              contract: latestSubjectPreviewContract.id,
            });
          })
        );
      }

      await contractUpdateMutation.mutateAsync({
        id: latestSubjectPreviewContract.id,
        status: ContractStatus.SENT_FOR_REVIEW,
      });
    }
  };

  const updateReviewers = async (
    newReviewers: number[],
    subject: IContractGenerationSubject
  ) => {
    const subjectContract = contracts?.find(
      (contract) =>
        contract.subject_id === subject.subjectId &&
        contract.contract_type__code === subject.contractType
    );

    const subjectReviewers = reviewers?.filter(
      (reviewer) => reviewer.contract === subjectContract?.id
    );

    const reviewersToRemove = subjectReviewers?.filter((reviewer) => {
      return !newReviewers.includes(reviewer.person!);
    });

    for (const reviewer of reviewersToRemove ?? []) {
      await deleteReviewerMutation.mutateAsync(reviewer.id!);
    }

    const reviewersToAdd = newReviewers.filter(
      (reviewer) =>
        !subjectReviewers?.some(
          (subjectReviewer) => subjectReviewer.person === reviewer
        )
    );

    for (const reviewer of reviewersToAdd) {
      await createReviewerMutation.mutateAsync({
        person: reviewer,
        contract: subjectContract?.id!,
      });
    }
  };

  const initSignNow = async (partySignatories: IPartySignatory[]) => {
    const createdSignatories = await Promise.all(
      partySignatories.map((partySignatory) => {
        return signatoryCreateMutation.mutateAsync({
          signatory: {
            party: partySignatory.party.id,
            person: partySignatory.person,
          },
        });
      })
    );

    for (const [index, signatory] of createdSignatories.entries()) {
      const signatorySignature = signatures?.find(
        (signature) => signature.signatory === signatory.id
      );

      try {
        if (signatorySignature) {
          await signNowUpdateMutation.mutateAsync({
            signature: {
              id: signatorySignature.id,
            },
          });
        } else {
          let role = `signatory_${index + 1}`;

          const signatoryParty = parties?.find(
            (party) => party.id === signatory.party
          );

          if (signatoryParty?.main) {
            role = "main";
          } else {
            if (signatoryParty?.subject_type === PartySubjectType.Company) {
              role = "company";
              const company = await CompanyService.getCompany(
                signatoryParty.subject_id!
              );
              if (company?.code) {
                role = company.code;
              }
            }
          }

          await signNowCreateMutation.mutateAsync({
            signature: {
              signatory: signatory.id,
              role,
            },
          });
        }
      } catch (e) {
        Promise.all(
          createdSignatories.map((signatory) => {
            return signatoryDeleteMutation.mutateAsync({
              signatory,
            });
          })
        );

        throw e;
      }
    }
  };

  const getError = () => {
    return (
      contractCreateMutation.error ||
      generateContractMutation.error ||
      partyCreateMutation.error ||
      signatoryCreateMutation.error ||
      signNowCreateMutation.error ||
      signNowUpdateMutation.error ||
      createReviewerMutation.error
    );
  };

  return {
    subjects,
    expectedContractTypes,
    contracts,
    generatePreview,
    generateFinal,
    initSignNow,
    areContractsLoading,
    isContractGenerating:
      generateContractMutation.isLoading ||
      contractCreateMutation.isLoading ||
      contractUpdateMutation.isLoading ||
      partyCreateMutation.isLoading ||
      signatoryCreateMutation.isLoading ||
      createReviewerMutation.isLoading ||
      deleteReviewerMutation.isLoading,
    arePartiesLoaded,
    parties,
    signatories,
    signatures,
    reviewers,
    updateReviewers,
    error: getError(),
  };
}
