import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { isEqual } from "lodash";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { Controller, set, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import CustomSelect from "@components/CustomSelect/CustomSelect";
import FieldErrorMessage from "@components/FieldErrorMessage/FieldErrorMessage";
import { isFieldRequired } from "@helpers/joi-utils";
import { useClients } from "@hooks/client/useClients";
import { useXchangeCreate } from "@hooks/xchange/useXchangeCreate";
import { useXchangeUpdate } from "@hooks/xchange/useXchangeUpdate";
import { ClientStatus } from "@interfaces/IClient";
import IXchange, { XchangeStatuses } from "@interfaces/IXchange";

export interface XchangeDetailsFormValues {
  name?: string;
  client?: number;
  description?: string;
  objective?: string;
  status?: XchangeStatuses;
}

export const xchangeDetailsValidationSchema = Joi.object({
  name: Joi.string().required(),
  client: Joi.number().required(),
  description: Joi.string().allow("").allow(null),
  objective: Joi.string().allow("").allow(null),
  status: Joi.string().required(),
});

export default function XchangeDetailsForm({
  type,
  xchange,
}: {
  type?: string;
  xchange: IXchange | undefined;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    control,
    getValues,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<XchangeDetailsFormValues>({
    mode: "onChange",
    resolver: joiResolver(xchangeDetailsValidationSchema),
    defaultValues: {
      name: xchange?.name,
      client: xchange?.client?.id!,
      description: xchange?.description,
      objective: xchange?.objective,
      status: xchange?.status,
    },
  });

  const { data: clients } = useClients({
    status: ClientStatus.ACTIVE,
  });

  const [pastOnBlurData, setPastOnBlurData] =
    useState<XchangeDetailsFormValues>(getValues());

  const createXchangeMutation = useXchangeCreate();
  const updateXchangeMutation = useXchangeUpdate();
  const formSubmit = (data: XchangeDetailsFormValues) => {
    if (!xchange) {
      createXchangeMutation.mutate(
        {
          type: type,
          ...data,
        },
        {
          onSuccess: (newXchange) => {
            navigate(`/xchanges/${newXchange.id}`, {
              replace: true,
            });
          },
        }
      );
    } else {
      const changedValues = Object.keys(data).reduce((acc, key) => {
        if (
          !isEqual(
            data[key as keyof XchangeDetailsFormValues],
            pastOnBlurData[key as keyof XchangeDetailsFormValues]
          )
        ) {
          set(acc, key, data[key as keyof XchangeDetailsFormValues]);
        }
        return acc;
      }, {} as XchangeDetailsFormValues);

      if (Object.keys(changedValues).length > 0) {
        updateXchangeMutation.mutate({
          updatedXchange: {
            id: xchange?.id!,
            ...changedValues,
          },
        });
      }
    }
    setPastOnBlurData(data);
  };

  return (
    <>
      <Form className="ps-4 pe-4 pb-4">
        <h5 className="mb-5">{t("form.xchange_edit.sub_titles.general")}</h5>
        <Form.Group className="mb-3">
          <Form.Label
            aria-required={isFieldRequired(
              xchangeDetailsValidationSchema,
              "name"
            )}
          >
            {t("form.xchange_edit.form_labels.title")}
          </Form.Label>
          <Form.Control
            type="text"
            {...register("name")}
            isInvalid={!!errors.name}
            disabled={createXchangeMutation.isLoading}
            onBlur={handleSubmit(formSubmit)}
          />
          <FieldErrorMessage
            field={errors.name}
            fieldName={t("form.xchange_edit.form_labels.title")}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label
            aria-required={isFieldRequired(
              xchangeDetailsValidationSchema,
              "client"
            )}
          >
            {t("form.xchange_edit.form_labels.client")}
          </Form.Label>
          <CustomSelect
            control={control}
            name="client"
            options={(clients || []).map((client) => ({
              value: client.id,
              label: client.name,
            }))}
            onAfterChange={(value) => {
              handleSubmit(formSubmit)();
            }}
            isLoading={createXchangeMutation.isLoading}
            isDisabled={createXchangeMutation.isLoading}
            onBlur={handleSubmit(formSubmit)}
          />
          <FieldErrorMessage
            field={errors.client}
            fieldName={t("form.xchange_edit.form_labels.client")}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label
            aria-required={isFieldRequired(
              xchangeDetailsValidationSchema,
              "status"
            )}
          >
            {t("form.xchange_edit.form_labels.status")}
          </Form.Label>
          <CustomSelect
            name="status"
            control={control}
            options={Object.values(XchangeStatuses).map((status) => ({
              value: status,
              label: t(`form.xchange_edit.statuses.${status}`) as string,
            }))}
            onAfterChange={() => {
              handleSubmit(formSubmit)();
            }}
          />

          <FieldErrorMessage
            field={errors.status}
            fieldName={t("form.xchange_edit.form_labels.status")}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            {t("form.xchange_edit.form_labels.description")}
          </Form.Label>
          <Controller
            control={control}
            name="description"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <>
                <ReactQuill
                  className={`${error && "ql-invalid"} ${
                    !xchange && "ql-disabled"
                  }`}
                  value={value}
                  onChange={(value) => {
                    if (value.replace(/(<([^>]+)>)/gi, "")) {
                      onChange(value);
                    } else {
                      onChange(null);
                    }
                  }}
                  readOnly={!xchange}
                  onBlur={(_, source) => {
                    if (source === "silent") {
                      return;
                    }
                    handleSubmit(formSubmit)();
                  }}
                />
                <FieldErrorMessage
                  field={error}
                  fieldName={t("form.xchange_edit.form_labels.description")}
                />
              </>
            )}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            {t("form.xchange_edit.form_labels.objective")}
          </Form.Label>
          <Controller
            control={control}
            name="objective"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <>
                <ReactQuill
                  className={`${error && "ql-invalid"} ${
                    !xchange && "ql-disabled"
                  }`}
                  value={value}
                  onChange={(value) => {
                    if (value.replace(/(<([^>]+)>)/gi, "")) {
                      onChange(value);
                    } else {
                      onChange(null);
                    }
                  }}
                  readOnly={!xchange}
                  onBlur={(_, source) => {
                    if (source === "silent") {
                      return;
                    }
                    handleSubmit(formSubmit)();
                  }}
                />
                <FieldErrorMessage
                  field={error}
                  fieldName={t("form.xchange_edit.form_labels.objective")}
                />
              </>
            )}
          />
        </Form.Group>
      </Form>
    </>
  );
}
